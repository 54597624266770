import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDreamJobAPI, submitDreamJobAPI } from "../../core/apiFunctions";
import { internalJobDTO } from "../../core/dto/dto.models";
import TextField from "../../utils/TextField";
import DisplayErrors from "../../utils/DisplayErrors";
import { useNavigate } from "react-router-dom";
import useFeedback from "../../utils/useFeedback";
import { handleOnChange } from "../../core/helperConst";
import MatchingBase from "../MatchingBase";

export default function DreamJob() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [errors, setErrors] = useState<string[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [dreamJobInitial, setDreamJobInitial] = useState<internalJobDTO>({} as internalJobDTO);
    const [dreamJob, setDreamJob] = useState<internalJobDTO>({} as internalJobDTO);

    const { showSuccess }  = useFeedback();

    useEffect(() => {
        getDreamJobAPI().then(response => {
            setDreamJob(response.data);
            setDreamJobInitial(response.data);
        }).catch(error => {
            setErrors(error.response.data);
        });
    }, []);

    // Determine if changes have been made, enabling editing state
    useEffect(() => {
        if (dreamJobInitial !== undefined && dreamJobInitial !== dreamJob) {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }, [dreamJobInitial, dreamJob]);

    const setOrigValues = () => {
        setDreamJob(dreamJobInitial!);
        setIsEditing(false);
        setErrors([]);
    }

    const submit = () => {
        submitDreamJobAPI(dreamJob!).then(() => {
            setDreamJobInitial(dreamJob);
            setErrors([]);
            showSuccess(t("successfullySaved"));
        }).catch(error => {
            setErrors(error.response.data);
        });
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{t("myDreamJob")}</h5>

                    <div className="row">
                        <TextField field="description" value={dreamJob?.description} as="textarea"
                            formFloating={false} onChange={handleOnChange(dreamJob, setDreamJob)}
                            placeholder={t("dreamJobPlaceholder")} height="125px" />
                    </div>

                    <MatchingBase
                        jobProperties={dreamJob!}
                        setJobProperties={setDreamJob}
                        submitJobProperties={submit}
                        resetJobProperties={setOrigValues}
                        isEditing={isEditing}
                    />

                    <div className="row">
                        <DisplayErrors errors={errors} />
                    </div>
                </div>
            </div>
        </>
    );
}
