import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRecrewterDetailsAPI, submitIdealCandidateAPI } from "../../core/apiFunctions";
import { idealCandidateDTO, recrewterDTO } from "../../core/dto/user.models";
import useFeedback from "../../utils/useFeedback";
import MatchingBase from "../MatchingBase";

export default function IdealCandidate() {
    const { t } = useTranslation();
    const { showSuccess }  = useFeedback();

    const [recrewter, setRecrewter] = useState<recrewterDTO>({} as recrewterDTO);
    const [idealCandidate, setIdealCandidate] = useState<idealCandidateDTO>({} as idealCandidateDTO);
    const [initialIdealCandidate, setInitialIdealCandidate] = useState<idealCandidateDTO>({} as idealCandidateDTO);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        getRecrewterDetailsAPI().then((response) => {
            setRecrewter(response.data);
            setInitialIdealCandidate(response.data.idealCandidate ?? {} as idealCandidateDTO);
            setIdealCandidate(response.data.idealCandidate ?? {} as idealCandidateDTO);
        }).catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        if (initialIdealCandidate !== undefined && initialIdealCandidate !== idealCandidate) {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }, [initialIdealCandidate, idealCandidate]);

    const setOrigValues = () => {
        setIdealCandidate(initialIdealCandidate);
        setIsEditing(false);
    }

    const submit = () => {
        submitIdealCandidateAPI(idealCandidate).then(() => {
            setInitialIdealCandidate(idealCandidate);
            showSuccess(t("successfullySaved"));
        }).catch((error: any) => console.error(error));
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">{t("idealCandidate")}</h5>
                    <MatchingBase
                        jobProperties={idealCandidate}
                        setJobProperties={setIdealCandidate}
                        submitJobProperties={submit}
                        resetJobProperties={setOrigValues}
                        isEditing={isEditing}
                    />
                </div>
            </div>
        </>
    );
}
