import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function TextField({
    type = 'text',
    formFloating = false,
    class: className = 'mb-3',
    isMandatory = false,
    disabled = false,
    ...props
}: textFieldProps) {

    const [fieldText, setFieldText] = React.useState<string>();
    const { t } = useTranslation();

    React.useEffect(() => {
        setFieldText(props.value);
    }, [props.value]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e);
            setFieldText(e.target.value);
        }
    }

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur) {
            setFieldText(e.target.value);
            props.onBlur(e);
        }
    }

    const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
        if (props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <>
            <div className={`${className} ${formFloating ? 'form-floating' : ''}`}>
                {
                    props.displayName && !formFloating &&
                    <label htmlFor={props.field}>{t(props.displayName)}</label>
                }
                {
                    props.icon &&
                    <img src={props.icon} alt={props.iconAlt} />
                }


                <Form.Control disabled={disabled} onBlur={handleOnBlur} type={type} className="form-control" id={props.field} name={props.field}
                    onChange={handleOnChange} value={fieldText} as={props.as} placeholder={props.placeholder} onClick={handleOnClick}
                    style={{ height: props.as === 'textarea' ? props.height : '' }} />


                {formFloating && props.displayName && <label htmlFor={props.field}>{t(props.displayName)}{isMandatory === true ? <b> *</b> : ""}</label>}
            </div>
            {props.validationMessage && <label className="text-danger">{props.validationMessage}</label>}
        </>
    )
}

interface textFieldProps {
    field: string;
    as?: 'textarea',
    height?: string;
    placeholder?: string;
    displayName?: string;
    icon?: string;
    iconAlt?: string;
    formFloating?: boolean;
    type?: 'text' | 'password' | 'date';
    class?: string;
    value?: string;
    disabled?: boolean;
    onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
    onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
    validationMessage?: string;
    isMandatory?: boolean;
    onClick?(event: React.MouseEvent<HTMLInputElement>): void;
}