import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface DropdownFieldProps {
  displayName: string;
  field: string;
  selected?: number | string | undefined;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  validationMessage?: string;
  className?: string;
  index?: number;
  indexSize?: number;
  disabled?: boolean;
  enumType: any;
  isMandatory?: boolean;
}

const DropdownField: React.FC<DropdownFieldProps> = ({
  className = 'mb-3',
  disabled = false,
  indexSize = 100,
  isMandatory = false,
  ...props
}) => {
  const { t } = useTranslation();

  const [enumOptions, setEnumOptions] = useState<string[]>([]);

  useEffect(() => {
    setEnumOptions(() => {
      const filteredKeys = Object.keys(props.enumType)
        .filter((k) => isNaN(Number(k)))
        .filter((k) => {
          if (props.index === undefined) {
            return true;
          } else {
            const enumKey = k as keyof typeof props.enumType;
            return (
              props.enumType[enumKey] === props.index ||
              (props.enumType[enumKey] >= props.index + 1 &&
                props.enumType[enumKey] < props.index + indexSize!)
            );
          }
        });
      if (props.index !== undefined) {
        return ['None', ...filteredKeys];
      } else {
        return filteredKeys;
      }
    });
  }, [props.enumType, props.index, indexSize]);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const enumValue = props.enumType[e.target.value as keyof typeof props.enumType];
    const newE = { ...e, target: { ...e.target, value: enumValue, name: e.target.name } };
    props.onChange(newE);
  };

  const selectedKey =
    Object.keys(props.enumType).find(
      (key) => props.enumType[key as keyof typeof props.enumType] === props.selected
    ) || '';

  return (
    <>
      <div className={className}>
        <FloatingLabel
          label={
            <>
              {t(props.displayName)}
              {isMandatory && <b> *</b>}
            </>
          }
        >
          <Form.Select
            id={props.field}
            name={props.field}
            disabled={disabled}
            value={selectedKey}
            onChange={onChange}
          >
            {enumOptions.map((key) => (
              <option key={key} value={key}>
                {t(key)}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
        {props.validationMessage && <label className="text-danger">{props.validationMessage}</label>}
      </div>
    </>
  );
};

export default DropdownField;
