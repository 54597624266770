import React from 'react';
import './Tile.scss';

export default function Tile(props: TileProperties) {
  const { entries = [], loading } = props;
  const hasEntries = entries.length > 0;

  return (
    <div className={`tile-container ${!hasEntries ? 'centered-title' : ''}`} onClick={props.onClick}>
      <h3 className="tile-title">{props.title}</h3>
      {
        loading ? (
          <div className="spinner-border spinner-border-sm" role="status" />
        ) : (
          hasEntries ? (
            <ul className="tile-list">
              {entries.map((entry, index) => (
                <li key={index} className="tile-list-item">
                  <div>{entry.name}</div>
                  <div className="tile-content">{entry.content}</div>
                  {
                    entry.date.length > 0 && entry.time.length > 0 ? <div>{entry.date}, {entry.time}</div> : null
                  }
                </li>
              ))}
            </ul>
          ) : null
        )
      }
    </div>
  );
}

interface Entry {
  name: string;
  date: string;
  time: string;
  content: string;
}

interface TileProperties {
  title: string;
  entries?: Entry[];
  loading?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}
