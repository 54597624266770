import React, { useContext, useEffect, useState } from 'react';
import Tile from '../components/Tile';
import './Dashboard.scss';
import Mainmenu from '../components/Menu/Mainmenu';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import WebSocketContext from '../contexts/WebSocketContext';
import {
    employeeSearchFilterAPI,
    getChatsAPI,
    getRecrewterDetailsAPI,
    getUserConnectionAPI,
    getUserInformationAPI
} from '../core/apiFunctions';
import {
    chatDTO,
    employeeSearchDTO,
    employeeSearchFilterDTO,
    participantDTO
} from '../core/dto/dto.models';
import { getNameIdentifier, getRoleId } from '../core/claimFunctions';
import { getClaims } from '../core/handleJWT';
import {
    Availability,
    EducationLevel,
    EmploymentType,
    JobPosition,
    Salary,
    Workload,
    Title,
    UserRoles,
    ConnectionStatus
} from '../config/Types/GeneralEnumDefinitions';
import { Country, State } from '../config/Types/PlaceEnumDefinitions';
import { MedicalDivision } from '../config/Types/MedicalEnumDefinitions';
import { userConnectionDTO } from '../core/dto/user.models';

interface InboxEntry {
    senderId: string;
    name: string;
    date: string;
    time: string;
    content: string;
    timestamp: number;
    unreadCount: number;
}

export default function Dashboard() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const role = getRoleId(getClaims());
    const i18nextLng = localStorage.getItem('i18nextLng') || 'en';
    const attributeMinMatchCount = 4;
    const messageDisplayLength = role !== UserRoles.Recrewter ? 200 : 100;
    const maxDisplayMessages = 5;

    const webSocketContext = useContext(WebSocketContext);
    const { messageReceivedSignalR } = webSocketContext || {};

    const [matchEntries, setMatchEntries] = useState<
        { name: string; date: string; time: string; content: string }[]
    >([]);
    const [loadingMatches, setLoadingMatches] = useState(false);
    const [inboxEntries, setInboxEntries] = useState<InboxEntry[]>([]);
    const [loadingInbox, setLoadingInbox] = useState(true);

    const userId = getNameIdentifier(getClaims());

    const fetchUserName = async (userId: string) => {
        try {
            const response = await getUserInformationAPI(userId);
            if (response.data) response.data.userId = userId;
            return response.data.name;
        } catch (error) {
            return t("unknownUser");
        }
    };

    const getParticipantName = async (participant: participantDTO): Promise<string> => {
        if ((participant.isRecrewter || participant.connectionStatus === 2) && !participant.name) {
            return await fetchUserName(participant.id);
        }
        return participant.name || '**********';
    };

    const fetchMessagesFromBackend = async () => {
        try {
            const response = await getChatsAPI();
            const messagesMap: { [key: string]: InboxEntry } = {};

            await Promise.all(
                response.data.map(async (chat: chatDTO) => {
                    if (!chat.messages || chat.messages.length === 0) {
                        return; // Skip chats with no messages
                    }

                    // Get latest message with the newest timestamp
                    const latestMessage = chat.messages.reduce((a, b) => (a.date > b.date ? a : b));

                    if (latestMessage.senderId === userId) {
                        return; // Do not display chats where the user sent the last message
                    }

                    const participant = chat.participants.find((p: participantDTO) => p.id === latestMessage.senderId);
                    let name = "";
                    if (participant) {
                        name = await getParticipantName(participant);
                    }

                    const contentPreview = latestMessage.content.length > messageDisplayLength
                        ? latestMessage.content.substring(0, messageDisplayLength) + '...'
                        : latestMessage.content;

                    const timestamp = new Date(latestMessage.date).getTime();

                    const unreadCount = chat.messages.filter(
                        (msg: any) => msg.senderId !== userId && !msg.isRead
                    ).length;

                    if (unreadCount > 0) {
                        messagesMap[latestMessage.senderId] = {
                            senderId: latestMessage.senderId,
                            name,
                            content: contentPreview,
                            date: new Date(latestMessage.date).toLocaleDateString(i18nextLng),
                            time: new Date(latestMessage.date).toLocaleTimeString().slice(0, 5),
                            timestamp,
                            unreadCount
                        };
                    }
                })
            );

            let fetchedMessages = Object.values(messagesMap);

            fetchedMessages.sort((a, b) => b.timestamp - a.timestamp);

            fetchedMessages = fetchedMessages.slice(0, maxDisplayMessages);

            if (Object.keys(messagesMap).length > maxDisplayMessages) {
                fetchedMessages.push({
                    senderId: '',
                    name: t('chat.moreUnreadMessages'),
                    content: '',
                    date: '',
                    time: '',
                    timestamp: 0,
                    unreadCount: 0
                });
            }

            setInboxEntries(fetchedMessages);
        } catch (error) {
            console.error('Error fetching messages from backend:', error);
        } finally {
            setLoadingInbox(false);
        }
    };

    useEffect(() => {
        fetchMessagesFromBackend();
    }, []);

    useEffect(() => {
        if (messageReceivedSignalR) {
            const handleNewMessage = async () => {
                const senderId = messageReceivedSignalR.senderId;

                if (senderId === userId) {
                    return;
                }

                let name = '**********';

                try {
                    const response = await getUserInformationAPI(senderId);
                    if (response.data && response.data.name) {
                        name = response.data.name;
                    } else {
                        name = t("unknownUser");
                    }
                } catch (error) {
                    console.error('Error fetching user information:', error);
                }

                const newMessageContent = messageReceivedSignalR.content.length > messageDisplayLength
                    ? messageReceivedSignalR.content.substring(0, messageDisplayLength) + '...'
                    : messageReceivedSignalR.content;

                const newMessageTimestamp = new Date(messageReceivedSignalR.date).getTime();

                setInboxEntries(prevEntries => {
                    const existingIndex = prevEntries.findIndex(entry => entry.senderId === senderId);

                    let updatedEntries: InboxEntry[] = [];

                    if (existingIndex !== -1) {
                        // Existing sender: update the entry
                        const existingEntry = prevEntries[existingIndex];
                        const updatedEntry = {
                            ...existingEntry,
                            name: name,
                            content: newMessageContent,
                            date: new Date(messageReceivedSignalR.date).toLocaleDateString(i18nextLng),
                            time: new Date(messageReceivedSignalR.date).toLocaleTimeString().slice(0, 5),
                            timestamp: newMessageTimestamp,
                            unreadCount: existingEntry.unreadCount + 1
                        };

                        updatedEntries = [
                            updatedEntry,
                            ...prevEntries.filter(entry => entry.senderId !== senderId)
                        ];
                    } else {
                        const newEntry: InboxEntry = {
                            senderId,
                            name,
                            content: newMessageContent,
                            date: new Date(messageReceivedSignalR.date).toLocaleDateString(i18nextLng),
                            time: new Date(messageReceivedSignalR.date).toLocaleTimeString().slice(0, 5),
                            timestamp: newMessageTimestamp,
                            unreadCount: 1
                        };

                        updatedEntries = [newEntry, ...prevEntries];
                    }

                    updatedEntries.sort((a, b) => b.timestamp - a.timestamp);

                    if (updatedEntries.length > maxDisplayMessages) {
                        const hasMoreUnread = updatedEntries.some(entry => entry.name === t('chat.moreUnreadMessages'));
                        if (!hasMoreUnread) {
                            updatedEntries = updatedEntries.slice(0, maxDisplayMessages);
                            updatedEntries.push({
                                senderId: '',
                                name: t('chat.moreUnreadMessages'),
                                content: '',
                                date: '',
                                time: '',
                                timestamp: 0,
                                unreadCount: 0
                            });
                        } else {
                            updatedEntries = updatedEntries.slice(0, maxDisplayMessages);
                        }
                    }

                    return updatedEntries;
                });

                setLoadingInbox(false);
            };

            handleNewMessage();
        }
    }, [messageReceivedSignalR, t, messageDisplayLength, i18nextLng, userId]);

    const fetchMatches = async () => {
        if (role !== UserRoles.Recrewter) return;

        try {
            setLoadingMatches(true);

            const recrewter = await getRecrewterDetailsAPI();
            if (!recrewter.data.idealCandidate) return;

            const filterDto: employeeSearchFilterDTO = {
                description: '',
                title: Title.None,
                country: recrewter.data.idealCandidate.country ?? Country.None,
                state: recrewter.data.idealCandidate.state ?? State.None,
                jobPosition: recrewter.data.idealCandidate.jobPosition ?? JobPosition.None,
                educationLevel: recrewter.data.idealCandidate.educationLevel ?? EducationLevel.None,
                availability: recrewter.data.idealCandidate.availability ?? Availability.None,
                division: recrewter.data.idealCandidate.division ?? MedicalDivision.None,
                workload: recrewter.data.idealCandidate.workload ?? Workload.None,
                salary: recrewter.data.idealCandidate.salary ?? Salary.None,
                employmentType: recrewter.data.idealCandidate.employmentType ?? EmploymentType.None
            };

            const matches = await employeeSearchFilterAPI(filterDto, false, attributeMinMatchCount);

            const matchesWithConnectionstatus = await Promise.all(matches.data.map(async (match: employeeSearchDTO) => {
                const response = await getUserConnectionAPI(match.userId).catch(() => {
                    return { data: { connectionStatus: ConnectionStatus.None } }
                });
                return {
                    userId: match.userId,
                    connectionStatus: response.data
                };
            }));

            const matchesWithConnectionMap: { [key: string]: userConnectionDTO } = {};
            matchesWithConnectionstatus.forEach((status) => {
                matchesWithConnectionMap[status.userId] = status.connectionStatus;
            });

            const formattedMatches = matches.data.map((match: employeeSearchDTO, index: number) => ({
                name: matchesWithConnectionMap[match.userId].connectionStatus !== ConnectionStatus.Accepted
                    ? `${t('potentialUser')} ${index + 1}`
                    : `${matchesWithConnectionMap[match.userId].firstName} ${matchesWithConnectionMap[match.userId].lastName}`,
                date: new Date().toLocaleDateString(i18nextLng),
                time: new Date().toLocaleTimeString().slice(0, 5),
                content: '',
                timestamp: 0
            }));

            setMatchEntries(formattedMatches);
        } catch (error) {
            console.error('Error fetching matches:', error);
        } finally {
            setLoadingMatches(false);
        }
    };

    useEffect(() => {
        fetchMatches();
    }, []);

    const displayInboxEntries = inboxEntries.length > 0
        ? inboxEntries.map(entry => {
            if (entry.senderId === '') {
                return {
                    name: entry.name,
                    date: entry.date,
                    time: entry.time,
                    content: entry.content
                };
            } else {
                return {
                    name: entry.unreadCount > 0
                        ? `${entry.name} (${t('chat.unreadMessages', { count: entry.unreadCount })})`
                        : entry.name,
                    date: entry.date,
                    time: entry.time,
                    content: entry.content
                };
            }
        })
        : [{
            name: t('chat.noUnreadMessages'),
            date: new Date().toLocaleDateString(i18nextLng),
            time: new Date().toLocaleTimeString().slice(0, 5),
            content: ''
        }];

    return (
        <>
            <Mainmenu />
            <div className={`home-container ${role !== UserRoles.Recrewter ? 'non-recrewter-layout' : ''}`}>
                {
                    role === UserRoles.Recrewter
                    ?
                        <Tile
                            title={t('mySuggestions')}
                            entries={matchEntries.length > 0
                                ? matchEntries
                                : [{
                                    name: t('noSuggestions'),
                                    date: new Date().toLocaleDateString(i18nextLng),
                                    time: new Date().toLocaleTimeString().slice(0, 5),
                                    content: ''
                                }]}
                            loading={loadingMatches}
                            onClick={() => navigate('/personal')}
                        />
                    :
                        null
                }
                <Tile
                    title={t('inbox')}
                    entries={displayInboxEntries}
                    loading={loadingInbox}
                    onClick={() => navigate('/chat')}
                />
                <Tile title={t('menuJobs')} onClick={() => navigate('/jobs')}/>
                <Tile title={t('myProfile')} onClick={() => navigate('/settings')} />
            </div>
        </>
    );
}
