import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../config/Types/GeneralEnumDefinitions';
import { State, Country } from '../../config/Types/PlaceEnumDefinitions';
import { getEmailSchema, getPasswordSchema, getConfirmPasswordSchema, getDateOfBirthSchema } from '../../utils/validationSchemas';
import DropdownField from '../../utils/DropdownField';
import * as yup from 'yup';
import './Chatbot.scss';
import { existsUserAPI, isEmailConfirmed } from '../../core/apiFunctions';
import { useNavigate } from 'react-router-dom';

const RegistrationChatbot: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [conversation, setConversation] = useState<Message[]>([
    { from: 'bot', message: t('bot.welcome') },
  ]);
  const [userInput, setUserInput] = useState<string>('');
  const [isTyping, setIsTyping] = useState(false);
  const [inputType, setInputType] = useState<'text' | 'password' | 'date'>('text');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userCredentials, setUserCredentials] = useState({
    email: '',
    password: '',
    verifyPassword: '',
  });
  const [userDetails, setUserDetails] = useState({
    id: '',
    title: Title.None,
    isPublic: false,
    notificationsEnabled: false,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    nationality: Country.None,
    residencePermit: '',
    state: State.None,
    email: '',
    phoneNumber: '',
    mobileNumber: '',
    linkedIn: '',
    xing: '',
    website: '',
    createdDate: null,
  });
  const [companyDetails, setCompanyDetails] = useState({
    id: '',
    name: '',
    description: '',
    address: '',
    city: '',
    postcode: '',
    state: State.None,
    country: Country.None,
    uid: '',
    email: '',
    phoneNumber: '',
    linkedIn: '',
    xing: '',
    website: '',
    logo: '',
    header: '',
    youTubeUrl: '',
    createdDate: null,
  });
  const chatBoxRef = useRef<HTMLDivElement>(null);

  enum YesNo {
    None = 0,
    No = 1,
    Yes = 2,
  }

  const questions = [
    {
      key: 'email',
      message: t('bot.startLoginEmail'),
      validation: getEmailSchema(t),
      type: 'text',
    },
    {
      key: 'password',
      message: t('bot.password'),
      validation: getPasswordSchema(t),
      type: 'password',
    },
    {
      key: 'verifyPassword',
      message: t('bot.confirmPassword'),
      validation: getConfirmPasswordSchema(t),
      type: 'password',
    },
    {
      key: 'firstName',
      message: t('bot.firstName'),
      validation: yup.object({
        firstName: yup.string().required(t('FirstNameRequired')),
      }),
      type: 'text',
    },
    {
      key: 'lastName',
      message: t('bot.lastName',
        { firstName: userDetails.firstName }),
      validation: yup.object({
        lastName: yup.string().required(t('LastNameRequired')),
      }),
      type: 'text',
    },
    {
      key: 'dateOfBirth',
      message: t('bot.birthday'),
      validation: getDateOfBirthSchema(t),
      type: 'date',
    },
    {
      key: 'nationality',
      message: t('bot.nationality'),
      validation: yup.object({
        nationality: yup.number().required(t('NationalityRequired')),
      }),
      type: 'dropdown',
      enumType: Country,
    },
    {
      key: 'state',
      message: userDetails.nationality === Country.Switzerland ? t('bot.canton') : t('bot.state'),
      validation: yup.object({
        state: yup.number().required(
          userDetails.nationality === Country.Switzerland ? t('CantonRequired') : t('StateRequired')
        ),
      }),
      type: 'dropdown',
      enumType: State,
      condition: (answers: any) => {
        const nationality = answers['nationality'];
        return [Country.Austria, Country.Germany, Country.Switzerland].includes(nationality);
      },
      dropdownProps: {
        index: userDetails.nationality === Country.Germany ? 8400 :
               userDetails.nationality === Country.Austria ? 1500 : 21600, // 21600 for Switzerland (Canton)
        indexSize: userDetails.nationality === Country.Germany ? 17 :
                   userDetails.nationality === Country.Austria ? 10 : 27 // 27 Cantons for Switzerland
      }
    },
    {
      key: 'residencePermitOption',
      message: t('bot.residencePermitQuestion'),
      type: 'dropdown',
      enumType: YesNo,
      condition: (answers: any) => {
        const nationality = answers['nationality'];
        return ![Country.Switzerland].includes(nationality);
      },
    },
    {
      key: 'residencePermit',
      message: t('bot.residencePermit'),
      validation: yup.object({
        residencePermit: yup.string().required(t('ResidencePermitRequired')),
      }),
      type: 'text'
    },
    // Here we can add more questions
  ];

  const handleSend = async () => {
    if (!userInput || !userInput.toString().trim()) return;

    const currentQuestion = questions[currentQuestionIndex];
    const key = currentQuestion.key;

    let displayMessage = userInput;

    if (currentQuestion.type === 'dropdown' && currentQuestion.enumType) {
      displayMessage = t(currentQuestion?.enumType[userInput as keyof typeof currentQuestion.enumType].toString() ?? 'None');
    }

    if (currentQuestion.type === 'password') {
      displayMessage = '•'.repeat(userInput.length);
    }

    // Append user's message to conversation
    setConversation((prev) => [...prev, { from: 'user', message: displayMessage }]);

    // Validate input
    if (currentQuestion.validation) {
      try {
        let dataToValidate: any = { [key]: userInput };

        if (key === 'verifyPassword') {
          dataToValidate = {
            password: userCredentials.password,
            verifyPassword: userInput,
          };
        }

        await currentQuestion.validation.validate(dataToValidate, { abortEarly: false });
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errorMessage = error.errors[0];
          setConversation((prev) => [...prev, { from: 'bot', message: errorMessage }]);

          if (key === 'verifyPassword') {
            // Reset password and confirmation if they don't match
            setUserCredentials((prev) => ({ ...prev, password: '', verifyPassword: '' }));

            setConversation((prev) => [
              ...prev,
              { from: 'bot', message: t('bot.restartPasswordProcess') }
            ]);

            setCurrentQuestionIndex(questions.findIndex(q => q.key === 'password'));
          } else {
            const errorMessage = error.errors[0];
            setConversation((prev) => [...prev, { from: 'bot', message: errorMessage }]);
          }
          setUserInput('');
          return;
        }
      }
    }

    // Check if user exists
    if (key === 'email') {
      try {
        const response = await existsUserAPI(userInput);
        if (response.data.sso) {
          setUserInput('');
          setIsTyping(true);
          setTimeout(() => {
            setConversation((prev) => [
              ...prev,
              { from: 'bot', message: t('ssoExists') },
            ]);
            setIsTyping(false);
          }, 1000);
          navigate('/login');
          return;
        } else if (response.data.exists) {
          const isEmailVerified = await isEmailConfirmed(userInput);
          if (isEmailVerified) {
            navigate('/login', { state: { email: userInput, role: response.data.role } });
            return;
          } else {
            navigate('/verifyEmail');
            return;
          }
        }
        // If user does not exist, continue registration process
      } catch (error) {
        setConversation((prev) => [
          ...prev,
          { from: 'bot', message: 'An error occurred. Please try again later.' },
        ]);
        setUserInput('');
        return;
      }
    }

    // Save input
    if (['email', 'password', 'verifyPassword'].includes(key)) {
      setUserCredentials((prev) => ({ ...prev, [key]: userInput }));
    } else if (key in userDetails) {
      if (key === 'nationality' || key === 'country' || key === 'state') {
        setUserDetails((prev) => ({ ...prev, [key]: Number(userInput) }));
      } else {
        setUserDetails((prev) => ({ ...prev, [key]: userInput }));
      }
    } else if (key in companyDetails) {
      setCompanyDetails((prev) => ({ ...prev, [key]: userInput }));
    }

    if (key === 'residencePermitOption') {
      if (Number(userInput) === YesNo.No) {
        setUserDetails((prev) => ({ ...prev, residencePermit: 'unknown' }));
        setUserInput('');
        setCurrentQuestionIndex((prev) => prev + 2);
        return;
      }
    }

    // Move to next question
    setUserInput('');
    setCurrentQuestionIndex((prev) => prev + 1);
  };

  useEffect(() => {
    // Check if all questions have been answered
    if (currentQuestionIndex >= questions.length) {
      setConversation((prev) => [
        ...prev,
        { from: 'bot', message: t('Thank you for providing all the information!') },
      ]);
      return;
    }

    const currentQuestion = questions[currentQuestionIndex];

    // Check condition if question needs to be skipped
    // For example we don't ask for the state if the nationality is not swiss, german or austrian
    if (currentQuestion.condition && !currentQuestion.condition(userDetails)) {
      setCurrentQuestionIndex((prev) => prev + 1);
      return;
    }

    setIsTyping(true);
    setTimeout(() => {
      setConversation((prev) => [...prev, { from: 'bot', message: currentQuestion.message }]);
      setInputType(currentQuestion.type === 'password' ? 'password' : 'text');
      if (currentQuestion.type === 'date') setInputType('date');
      setIsTyping(false);
    }, 1000);
  }, [currentQuestionIndex, t, userDetails]);

  // Scroll down to newest message
  useEffect(() => {
    chatBoxRef.current?.scrollTo(0, chatBoxRef.current.scrollHeight);
  }, [conversation]);

  // Allow user to type enter to send the message
  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  // Typing indicator from the bot
  const typingIndicator = (
    <div className="typing">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );

  return (
    <div className="chat-container">
      <div className="chat-box" ref={chatBoxRef}>
        {conversation.map((messageObj, index) => (
          <div
            key={index}
            className={`chat-message ${
              messageObj.from === 'user' ? 'user-message' : 'bot-message'
            }`}
          >
            {messageObj.message}
          </div>
        ))}
        {isTyping && typingIndicator}
      </div>
      <div className="chat-input">
        {questions[currentQuestionIndex]?.type === 'dropdown' ? (
          <DropdownField
            displayName={t("bot.selectOption")}
            field={questions[currentQuestionIndex].key}
            enumType={questions[currentQuestionIndex].enumType}
            index={questions[currentQuestionIndex].dropdownProps?.index}
            indexSize={questions[currentQuestionIndex].dropdownProps?.indexSize}
            selected={userInput}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setUserInput(e.target.value)}
            isMandatory={true}
          />
        ) : (
          <input
            type={inputType}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleInputKeyPress}
            placeholder={t('bot.typeAnswer')}
          />
        )}
        <button onClick={handleSend}>{t('btnSend')}</button>
      </div>
    </div>
  );
};

interface Message {
  from: 'user' | 'bot';
  message: string;
}

export default RegistrationChatbot;
