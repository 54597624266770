import React from "react";
import { useTranslation } from "react-i18next";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { EmploymentType, Availability, JobPosition, Workload, Salary, EducationLevel } from "../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../config/Types/PlaceEnumDefinitions";
import { handleOnChange, listOfCountriesWithStates } from "../core/helperConst";
import DropdownField from "../utils/DropdownField";

export default function MatchingBase<T extends JobPropertiesBase>({
    jobProperties,
    setJobProperties,
    submitJobProperties,
    resetJobProperties,
    isEditing
}: MatchingBaseProps<T>) {
    const { t } = useTranslation();
    const onChange = handleOnChange(jobProperties, setJobProperties);

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <DropdownField displayName="headerCountry" field="country" enumType={Country}
                        selected={jobProperties?.country}
                        onChange={onChange} />
                </div>
                <div className="col-md-4">
                    {/* State dropdown (will change to Canton if Switzerland is selected) */}
                    <DropdownField
                        displayName={jobProperties?.country === Country.Switzerland ? "headerCanton" : "headerState"}
                        field="state"
                        enumType={State}
                        index={
                            jobProperties?.country === Country.Germany ? 8400 :
                            jobProperties?.country === Country.Austria ? 1500 :
                            21600 // Default for Switzerland
                        }
                        indexSize={
                            jobProperties?.country === Country.Germany ? 17 :
                            jobProperties?.country === Country.Austria ? 10 :
                            27 // Default for Switzerland (Canton)
                        }
                        selected={!listOfCountriesWithStates.includes(jobProperties?.country) ? State.None : jobProperties?.state!}
                        onChange={onChange}
                        disabled={jobProperties?.country === Country.None || !(listOfCountriesWithStates.includes(jobProperties?.country!))}
                    />
                </div>
                <div className="col-md-4">
                    <DropdownField displayName="headerEmployementType" field="employmentType" enumType={EmploymentType}
                        selected={jobProperties?.employmentType!}
                        onChange={onChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <DropdownField displayName="headerAvailability" field="availability" enumType={Availability}
                        selected={jobProperties?.availability!}
                        onChange={onChange} />
                </div>
                <div className="col-md-4">
                    <DropdownField displayName="headerPosition" field="jobPosition" enumType={JobPosition}
                        selected={jobProperties?.jobPosition!}
                        onChange={onChange} />
                </div>
                <div className="col-md-4">
                    <DropdownField displayName="headerPensum" field="workload" enumType={Workload}
                        selected={jobProperties?.workload!}
                        onChange={onChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <DropdownField displayName="headerSalary" field="salary" enumType={Salary}
                        selected={jobProperties?.salary!}
                        onChange={onChange} />
                </div>
                <div className="col-md-4">
                    <DropdownField displayName="headerDivision" field="division"
                        enumType={MedicalDivision} selected={jobProperties?.division} onChange={onChange} />
                </div>
                <div className="col-md-4">
                    <DropdownField displayName="headerEducationLevel" field="educationLevel" enumType={EducationLevel}
                        selected={jobProperties?.educationLevel} onChange={onChange} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Button className="btn btn-primary" hidden={!isEditing} type="button" onClick={submitJobProperties}>
                        <FontAwesomeIcon icon={faSave} /> {t("save")}
                    </Button>
                    <Button className="btn btn-danger ms-3" hidden={!isEditing} type="button" onClick={resetJobProperties}>
                        <FontAwesomeIcon icon={faUndo} /> {t("cancel")}
                    </Button>
                </div>
            </div>
        </>
    );
}

interface JobPropertiesBase {
    country: Country;
    state?: State;
    employmentType?: EmploymentType;
    availability?: Availability;
    jobPosition?: JobPosition;
    workload?: Workload;
    salary?: Salary;
    division?: MedicalDivision;
    educationLevel?: EducationLevel;
}

interface MatchingBaseProps<T extends JobPropertiesBase> {
    jobProperties: T;
    setJobProperties: React.Dispatch<React.SetStateAction<T>>;
    submitJobProperties: () => void;
    resetJobProperties: () => void;
    isEditing: boolean;
}