import React, { useContext, useEffect, useState, useRef } from "react";
import { userDetailsDTO } from "../../core/dto/user.models";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import Button from "../../utils/Button";
import { getUserDetailsAPI, submitFileAPI, submitUserDetailsAPI } from "../../core/apiFunctions";
import AuthenticationContext from "../../auth/AuthenticationContext";
import TextField from "../../utils/TextField";
import { FileType, Title, UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import { State, NationalityType } from "../../config/Types/PlaceEnumDefinitions";
import DropdownField from "../../utils/DropdownField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useSetState } from "../../utils/UseSetState";
import ProfileHeader from "./ProfileHeader";
import { checkRoles } from "../../core/claimFunctions";
import useFeedback from "../../utils/useFeedback";
import { saveToken, getClaims } from "../../core/handleJWT";
import "../../utils/ProgressWithToolTip.scss";
import { Nationalities } from "../../core/helperConst";

export default function UserDetails(props: UserDetailsProperties) {
    const { t } = useTranslation();
    const { claims } = useContext(AuthenticationContext);
    const { update } = useContext(AuthenticationContext);

    const fieldErrorEmptyValues = {
        title: '', firstName: '', lastName: '', dateOfBirth: '', nationality: '',
        state: '', residencePermit: '', mobileNumber: '', email: ''
    };
    const schema = yup.object({
        title: yup.number().min(0, t('TitleIsRequired')),
        firstName: yup.string().required(t('FirstNameRequired')),
        lastName: yup.string().required(t('LastNameRequired')),
        dateOfBirth: yup.string().required(t('BirthdayRequired')),
        nationality: yup.string().required(t('NationalityRequired')),
        state: yup.string().required(t('StateRequired')).notOneOf([State.None.toString()], t('StateRequired')),
        residencePermit: yup.string().when("nationality", {
            is: (value: string) => isNationality(NationalityType.SWISS, value),
            then: yup.string().notRequired(),
            otherwise: yup.string().required(t('ResidencePermitRequired')),
        }),
        mobileNumber: yup.string().required(t('MobileNumberRequired')),
        email: yup.string().required(t('EmailRequired')),
    });

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [userDetailsInitial, setUserDetailsInitial] = useState<userDetailsDTO>();
    const [userDetails, setUserDetails] = useState<userDetailsDTO>();
    const [errors, setErrors] = useState<string[]>([]);
    const [fieldErrors, setFieldErrors, getFieldErrors] = useSetState(fieldErrorEmptyValues);
    const { showSuccess } = useFeedback();

    const toggleRef = useRef<HTMLInputElement>(null);

    const validateNestedSchema = async () => {
        try {
            await schema.validate(userDetails, { abortEarly: false });

            // If validation is successful, reset the field errors
            setFieldErrors(fieldErrorEmptyValues);
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const validationErrors: any = {};
                error.inner.forEach((innerError: any) => {
                    if (innerError.type === 'required' || innerError.type === 'notOneOf') {
                        validationErrors[innerError.path] = innerError.message;
                    }
                });
                setFieldErrors(validationErrors);
            }
        }
    };

    // validate the changed field and sets the error message
    const handleOnChange = (e: any) => {
        if (!isEditing) {
            setIsEditing(true);
        }

        const { name, value } = e.target;
        if (name === 'nationality') {
            // Clear or set default state when changing nationality
            setUserDetails({ ...userDetails!, [name]: value, state: State.None });
        } else {
            // verify input with yup schema
            if (name in fieldErrors) {
                schema.validateAt(name, { [name]: value })
                    .then(() => {
                        setFieldErrors({ ...fieldErrors, [name]: '' });
                    })
                    .catch(err => {
                        setFieldErrors({ ...fieldErrors, [name]: err.errors[0] });
                    });
            }
            setUserDetails({ ...userDetails!, [name]: value });
        }
    };

    // reset the form to the original values
    const setOrigValues = () => {
        setUserDetails(userDetailsInitial);
        setIsEditing(false);
        setErrors([]);
        setFieldErrors(fieldErrorEmptyValues);
    };

    const submit = async () => {
        try {
            await validateNestedSchema();
            if (errors.length === 0 && Object.values(await getFieldErrors()).every(x => x === '')) {
                const response = await submitUserDetailsAPI(userDetails!);
                setUserDetailsInitial(userDetails);
                saveToken(response.data);
                update(getClaims());
                setIsEditing(false);
                showSuccess(t("successfullySaved"));

                if (props.onUserProfileCompleted) {
                    props.onUserProfileCompleted();
                }
            }
        } catch (error: any) {
            if (Array.isArray(error.response.data)) {
                setErrors(error.response.data);
            } else {
                setErrors([error.response.data]);
            }
        }
    };

    const handleToggleChange = (e: any) => {
        const isChecked = e.target.checked;
        setUserDetails((prevDetails) => ({ ...prevDetails!, isPublic: isChecked }));
        setIsEditing(true);
    };

    useEffect(() => {
        getUserDetailsAPI().then(response => {
            setUserDetailsInitial(response.data);
            setUserDetails(response.data);
        }).catch(error => {
            setErrors(error.response.data);
        });
    }, []);

    useEffect(() => {
        if (toggleRef.current && typeof props.isProfileVisible !== "undefined") {
            toggleRef.current.checked = props.isProfileVisible;
            setUserDetails((prevDetails) => ({ ...prevDetails!, isPublic: props.isProfileVisible }));
        }
    }, [props.isProfileVisible]);

    function isNationality(type: NationalityType, value?: string): boolean {
        return value ? Nationalities[type]?.includes(value.toLowerCase()) ?? false : false;
    }

    return (
        <div className="card mt-0">
            <div className="card-header header-background">
                <div className="col-12">
                    <h5 className="card-title">{t("userProfile")}</h5>
                    <ProfileHeader
                        profilePhotoType={FileType.UserProfilePicture}
                        headerPhotoType={FileType.UserHeader}
                        apiCallToSubmitFile={submitFileAPI} />
                    {
                        checkRoles(claims, [UserRoles.Employee]) &&
                        <div className="form-check form-switch public-toggle progressWithToolTip">
                            <input
                                ref={toggleRef}
                                className="form-check-input mb-1"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                checked={userDetails?.isPublic}
                                onChange={handleToggleChange}
                            />

                            <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">{userDetails?.isPublic === true ? t("profileOnlyRecrewter") : t("profileOnlyEmployee")}</label>
                        </div>
                    }
                </div>
            </div>

            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <DropdownField displayName="title" field="title" selected={userDetails?.title!} onChange={handleOnChange}
                            enumType={Title} validationMessage={fieldErrors.title} isMandatory={true} />
                    </div>
                    <div className="col-md-3">
                        <TextField field="firstName" value={userDetails?.firstName} displayName="firstName"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.firstName} isMandatory={true} />
                    </div>
                    <div className="col-md-3">
                        <TextField field="lastName" value={userDetails?.lastName} displayName="lastname"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.lastName} isMandatory={true} />
                    </div>
                    <div className="col-md-3">
                        <TextField field="dateOfBirth" value={userDetails?.dateOfBirth} displayName="birthday"
                            formFloating={true} type="date" onChange={handleOnChange} validationMessage={fieldErrors.dateOfBirth} isMandatory={true} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <TextField field="nationality" value={userDetails?.nationality} displayName="nationality"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.nationality} isMandatory={true} />
                    </div>

                    {
                        isNationality(NationalityType.SWISS, userDetails?.nationality) &&
                        (
                            <div className="col-md-3">
                                {/* Swiss nationality, ask only for canton */}
                                <DropdownField displayName="canton" field="state" selected={userDetails?.state!} onChange={handleOnChange}
                                    enumType={State} validationMessage={fieldErrors.state} isMandatory={true} index={21600} indexSize={27} />
                            </div>
                        )
                    }
                    {
                        !isNationality(NationalityType.SWISS, userDetails?.nationality) &&
                        (
                            <>
                                {
                                    isNationality(NationalityType.GERMAN, userDetails?.nationality) &&
                                    (
                                        <div className="col-md-3">
                                            <DropdownField displayName="state" field="state" selected={userDetails?.state!} onChange={handleOnChange}
                                                enumType={State} validationMessage={fieldErrors.state} isMandatory={true} index={8400} indexSize={17} />
                                        </div>
                                    )
                                }
                                {
                                    isNationality(NationalityType.AUSTRIAN, userDetails?.nationality) &&
                                    (
                                        <div className="col-md-3">
                                            <DropdownField displayName="state" field="state" selected={userDetails?.state!} onChange={handleOnChange}
                                                enumType={State} validationMessage={fieldErrors.state} isMandatory={true} index={1500} indexSize={10} />
                                        </div>
                                    )
                                }
                                <div className="col-md-3">
                                    <TextField field="residencePermit" value={userDetails?.residencePermit} displayName="residencePermit"
                                        formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.residencePermit} isMandatory={true} />
                                </div>
                            </>
                        )
                    }
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <TextField field="phoneNumber" value={userDetails?.phoneNumber} displayName="phoneNumber"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                    <div className="col-md-3">
                        <TextField field="mobileNumber" value={userDetails?.mobileNumber} displayName="mobileNumber"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.mobileNumber} isMandatory={true} />
                    </div>
                    <div className="col-md-3">
                        <TextField field="email" value={userDetails?.email} displayName="eMail"
                            formFloating={true} onChange={handleOnChange} validationMessage={fieldErrors.email} isMandatory={true} />
                    </div>
                    <div className="col-md-3">
                        <TextField field="website" value={userDetails?.website} displayName="website"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <TextField field="linkedin" value={userDetails?.linkedIn} displayName="linkedIn"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                    <div className="col-md-6">
                        <TextField field="xing" value={userDetails?.xing} displayName="xing"
                            formFloating={true} onChange={handleOnChange} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <Button class="btn btn-primary" hidden={!isEditing} type="button" onClick={submit}>
                            <FontAwesomeIcon icon={faSave} /> {t("save")}
                        </Button>
                        <Button class="btn btn-danger ms-3" hidden={!isEditing} type="button" onClick={setOrigValues}>
                            <FontAwesomeIcon icon={faUndo} /> {t("cancel")}
                        </Button>
                    </div>
                </div>

                {/* only show errors when available */}
                {errors.length > 0 && <div className="row">
                    <div className="col-12">
                        <div className="alert alert-danger" role="alert">
                            <ul>
                                {errors.map((error: any, index: number) => {
                                    return <li key={index}>{error}</li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

interface UserDetailsProperties {
    isProfileVisible: boolean;
    onUserProfileCompleted?: () => void;
}
